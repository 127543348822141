<template>
  <div style="height: 100%; width: 100%; position: absolute; top: 0; bottom: 0; right: 0;">
    <l-map :zoom="zoom" :center="[42.858111, 74.621111]" @update:zoom="zoomUpdate">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <Vue2LeafletHeatmap v-if="coords.length" :lat-lng="coords" :radius="25" :min-opacity="1" :max-zoom="13" :blur="35" />
      <l-control style="width: 265px">
        <v-card>
          <VueCtkDateTimePicker
              id="date"
              v-model="date"
              :range="temp"
              :firstDayOfWeek="week"
              :onlyDate="onlyDate"
              button-color="#3FC44F"
              right
              autoClose
              class="mt-2"
              color="#3FC44F"
              format="MM-DD-YYYY"
              :formatted="formatted"
              label="Выберите промежуток времени"
              locale="ru"/>
          <v-row no-gutters class="justify-space-around">
            <v-btn small text>Кол.: {{ coords.length }}</v-btn>
            <v-btn color="primary" :disabled="disabled" small @click="fetchCoords">Show</v-btn>
            <v-btn color="warning" small @click="destroy">Clear</v-btn>
          </v-row>
        </v-card>
      </l-control>
    </l-map>
  </div>
</template>

<script>
import {LMap, LTileLayer, LControl} from "vue2-leaflet";
import Vue2LeafletHeatmap from "./leaflet.vue";
import format from "date-fns/format";
import axios from "@/services/axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    Vue2LeafletHeatmap
  },
  data() {
    return {
      tempo: [],
      disabled: false,
      date: {},
      temp: true,
      week: 1,
      onlyDate: true,
      formatted: 'll',
      coords: [],
      zoom: 13,
      currentZoom: 13,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    fetchCoords() {
      let payload = {};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios.send({
            url: "/orders/cords?" + queryString,
            method: "GET",
          }).then((resp) => {
            this.coords = [];
            for (let i = 0; i < resp.data.length; i++) {
              let c = resp.data[i]
              this.coords.push([c.lat, c.lng, 1])
        }
            this.disabled = true;
      });
    },
    destroy() {
      this.date = {};
      this.coords = [];
      this.coords.length = 0;
      this.disabled = false;
    }
  }
};
</script>
